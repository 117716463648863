/*----------------------------------------*/
/*  05. Header CSS
/*----------------------------------------*/

/* Header Section */

.header-section {
    background-color: rgba(8, 5, 5, 0.441);;
    z-index: 9999;

    min-height: 100px;
    border-bottom: 1px solid $white-opacity-50;
    // Responsive
    @media #{$tablet-device}{
        min-height: 80px;
    }
    @media #{$large-mobile}{
        min-height: 80px;
    }
 


    & .header-inner {

        display: flex;
        align-items: center;

        min-height: 100px;
        // Responsive
        @media #{$tablet-device}{
            min-height: 80px;
        }
        @media #{$large-mobile}{
            min-height: 80px;
        }
    }
    &.is-sticky {
        & .header-inner {
            position: fixed;
         
            top: 0;
            left: 0;
            background-color: #030e22 !important;
            width: 100%;
            height: 80px;
            animation: headerSlideDown 0.95s ease forwards;

            box-shadow: 0 8px 20px 0 rgba($black, 0.1);
        }
    }

    // Header Fluid
    &.header-fluid {
        & .container {
            max-width: 100%;

            @media #{$extraBig-device} {
                padding-right: 150px;
                padding-left: 150px;
            }
        }
    }
    &.header-fluid-two {
        & .container {
            max-width: 100%;

            @media #{$extraBig-device} {
                padding-right: 240px;
                padding-left: 240px;
            }
        }
    }

    // Header Shadow
    &.header-shadow {
        & .header-inner {
            box-shadow: 0 3px 9px rgba(0, 0, 0, 0.05);
        }
    }

    // Header Transparent
    &.header-transparent {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;

        & .header-inner {
            background-color: transparent;
        }
    }

    // Header Light
    &.header-light {
        & .header-login {
            & a {
                color: $white;

                &:hover {
                    color: $white;
                }
            }
        }
    }
}

@keyframes headerSlideDown {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

@import "header/logo";

/* header Search Area */ 
.header-search-area{
    display: flex;
    align-items: center;
}

/* Header Right */

.header-search {
    & button {
        background-color: transparent;
        border: 0;
        line-height: 1;

        display: flex;

        padding: 6px 5px;

        color: $white;

        & i {
            font-size: 26px;
        }

        &:hover {
            color: $primary;
        }
    }

    @media #{$extra-small-mobile} {
        & a {
            padding-right: 7px;
            padding-left: 7px;
        }
    }
}
.header_dropdown{
    display: flex;
    justify-content: space-between;
}
.contact_no ul{
    display: flex;
    justify-content: space-evenly;
    color: white;
    font-size: 16px;
    font-weight: bold;
    list-style-type: none;
    margin-left: -30px;
}
.contact_no ul li{
    padding-right: 15px;
}
.contact_no ul li i{
    
        padding-right: 18px;
        padding-left: -30px;
    
}
.mail_address{
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin-left: -30px;
}
.mail_address i{
        padding-right: 18px;
        padding-left: -30px;
}
.header_dropdown{
    margin-top: 15px;
}
button.Inquire_bttn {
    background-color: #1292ee;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 8px 15px 10px 15px;
    cursor: pointer;
    margin-right: -37px;
    margin-top: -8px;
    margin-left: 6px;
}
@media screen and (max-width: 992px) {
    .contact_no ul{
        display: inline-block;
    }
}
@media screen and (max-width: 1200px) {
    .contact_no ul{
        font-size: 12px;
    }
    .mail_address {
        font-size: 12px;
    }
}
@media screen and (max-width: 768px) {
    .mail_address {
        text-align: end;
    }
    ul.contact_ul {
        width: 86%;
    }
    a.phonelink2 {
        display: none;
    }
    a.phonelink3 {
        display: none;
    }
    a.phonelink4 {
        display: none;
    }
    a.phonelink6 {
        display: none;
    }
    a.phonelink7 {
        display: none;
    }
    .contact_no ul{
        display: flex;
        margin-left: -13px;
    }
}
@media screen and (max-width: 550px) {
    

li.contact_ul-li {
    width: 72%;
}
}
@media screen and (max-width: 460px) {
    .contact_no ul {
        font-size: 9px;
    }
    li.contact_ul-li {
        width: 100%;
    }
}
@import "header/search";
@import "header/main-menu";
@import "header/mobile-menu-toggle";
@import "header/mobile-menu";
/*----------------------------------------*/
/*  12. Footer CSS
/*----------------------------------------*/

.footer-bg-color{
    background-color: #030e22;
}

.footer-section {
    padding: 100px 0 0px;
    overflow: hidden;
    // Responsive
    @media #{$tablet-device}{
        padding: 80px 0 0px;
    }
    @media #{$large-mobile}{
        padding: 60px 0 0px;
    }
}

.copyright {
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    padding: 20px 15px;
    border-top: 1px solid $gray-200;
    color: $white;
    margin-bottom: 0;
}
.footer_link h5{
    color: white;
}
.contact{
    list-style-type: none;
    line-height: 3;
    color: #748494;
}
.mail_addres{
    color: #748494;
    margin-left: 32px;
    line-height: 3;
}
.header i{
    margin-right: 13px;
    margin-left: -30px;
}